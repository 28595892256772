.social-media-links-container {
  display: flex;
  justify-content: flex-end;
}

.social-media-link {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  margin-left: 30px;

  @media (min-width: 1280px) {
    width: 74px;
    height: 74px;
  }
}
