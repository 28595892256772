.shop {
  position: relative;

  .menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #002e28;
    padding: 20px;

    @media (min-width: 1440px) {
      background-color: unset;
      position: absolute;
      top: 10px;
      padding: unset;
    }
  }
}

.shop-title {
  font-size: 11rem;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 8rem;
  }

  @media (min-width: 1024px) {
    margin-top: 40px;
  }
}

.shop_content {
  display: flex;

  &__left {
    display: flex;
    flex-direction: column;

    @media (min-width: 1440px) {
      width: 40%;
    }

    .social-media-links-container {
      justify-content: flex-start;
      margin-top: 20px;

      @media (min-width: 1440px) {
        margin-top: unset;
      }
    }

    .social-media-link {
      background-color: #dfe3e2;
      margin: 0 45px 0 0;
    }
  }
}