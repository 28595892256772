.card {
  border: 1px solid #002d28;
  border-radius: 30px;
  padding: 74px 22px;
  height: 302px;

  @media (min-width: 1280px) {
    border-radius: 73px;
    border-width: 4px;
  }
}

.card-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  font-style: normal;

  @media (min-width: 1920px) {
    font-size: 25px;
  }
}

.card-subtitle,
.card-list {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  text-align: left;

  @media (min-width: 1280px) {
    padding: 0 0 0 20px;
  }

  @media (min-width: 1920px) {
    font-size: 16px;
  }
}

.card-price-container {
  position: absolute;
  right: -5px;
  bottom: -45px;
}

.card-price {
  font-weight: 700;
  border: 1px solid #002d28;
  width: 100px;
  height: 90px;
  display: flex;
  align-items: center;
  border-radius: 20%;
  justify-content: center;
  background: white;

  @media (min-width: 1280px) {
    border-width: 3px;
  }

}

.card-price-details {
  font-weight: 700;
  margin-top: 4px;
  min-height: 18px;
}

.card.preisliste--info {
  .preisliste--title {
    color: #dfe3e2;
    font-size: 6rem;
    font-weight: 600;
    line-height: .85;
    text-transform: uppercase;

    @media (max-width: 1600px) {
      font-size: 4rem;
    }
  }
}