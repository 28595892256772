.willkomen {

  &--left,
  &--right {
    box-sizing: border-box;
    align-self: center;

    @media (min-width: 1440px) {
      width: 50%;
    }
  }

  &--left {
    text-align: left;

    @media (min-width: 14400px) {
      padding: 20px 20px 50px;
    }
  }

  &--right {
    display: flex;
    padding: 0 0 20px;
    flex-direction: column;
    text-align: left;

    @media (min-width: 1440px) {
      margin-bottom: -150px;
      padding: 200px 120px 0 70px;
      flex-direction: row;
      text-align: unset;
    }
  }

  &--content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column-reverse;
    padding: 40px 20px;
    width: 100%;
    border-bottom: 150px solid #002e28;

    @media (min-width: 1440px) {
      flex-direction: row;
      padding: 70px 0;
      border-bottom: 200px solid #002e28;
    }
  }

  &--section-left {
    @media (min-width: 1440px) {
      padding-left: 250px;
    }
  }

  &--section-right {
    @media (min-width: 1024px) {
      padding-right: 70px;
    }
  }

  &--buttons {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: flex-start;

    @media (min-width: 1440px) {
      flex-direction: row;
      float: right;
      padding-top: unset;
      align-items: unset;
      padding-top: 40px;
    }

    a {
      text-align: center;
      text-decoration: none;
      line-height: 40px;
      margin-bottom: 20px;

      @media (min-width: 1440px) {
        margin-bottom: unset;
      }
    }

    button {
      @media (min-width: 1440px) {
        margin-left: 10px;
      }
    }
  }

  &--img {
    padding-top: 20px;
    height: 286px;
    height: 430px;
    margin: 0 auto;

    @media (min-width: 1440px) {
      padding-top: 100px;
    }
  }
}