.preisliste {
  &--container {
    box-sizing: content-box;
  }

  &--price-info-container {
    margin-bottom: 55px;
    margin-top: 40px;
  }

  &--title {
    margin-bottom: 20px;
    margin-top: 40px;

    @media (min-width: 1440px) {
      margin-top: 35px;
      margin-bottom: 40px;
    }
  }

  &--card {
    margin: 0;
    position: relative;

    @media (min-width: 1440px) {
      margin: 0 20px;
    }

    .card {
      height: 190px;
      padding-top: 20px;

      @media (min-width: 1440px) {
        height: 200px;
        padding-top: 45px;
      }

      li {
        color: #434343;
      }
    }
  }

  &--item-wrapper {
    @media (min-width: 1440px) {
      width: 25%;
    }
  }

  &--item-title {
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
  }

  &--item {
    padding: 5px;
    border-bottom: 3px solid #002d28;

    @media (min-width: 1440px) {
      border-right: 3px solid #002d28;
      border-bottom: none;
    }
  }

  &--item-4 {
    border: none;
  }

  &--subcard {
    border: none;
    text-align: left;
    padding-bottom: 10px;
    height: 190px;

    .card-title {
      text-align: left;
      color: #434343;
    }

    ul {
      list-style-position: inside;
      padding-inline-start: 0;

      li {
        color: #434343;
      }
    }
  }

  &--items-container {
    display: flex;
    flex-direction: column;

    @media (min-width: 1440px) {
      flex-direction: row;
    }
  }

  &--price-info-container {
    display: flex;

    .preisliste--title {
      text-align: left;
    }

    svg {
      width: 100px;
      height: 100px;

      @media (min-width: 1440px) {
        width: unset;
        height: unset;
      }
    }
  }

  &--info {
    border: none;
    padding: 0;
    padding-left: 20px;

    @media (min-width: 1440px) {
      width: 40%;
      padding-left: 40px;
    }

    &.card {
      height: unset;
    }

    .title {
      color: #808080;
      opacity: 0.1;
      font-size: 80px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: normal;
      line-height: 101px;
      text-transform: uppercase;
      width: 35%;
      margin: 0;
    }

    ul {
      list-style-position: inside;
      padding-inline-start: 0;

      li {
        color: #434343;
      }
    }
  }

  &--booking-button {
    margin-left: unset;

    @media (min-width: 1280px) {
      margin-left: 34px;
    }
  }
}