.tier-physio {
  &--container {
    background-position: 0 -20px;
    border-bottom: 150px solid #002e28;

    @media (min-width: 1280px) {
      border-bottom: unset;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 40px 0 20px;

    @media (min-width: 1440px) {
      width: 50%;
      margin: 50px 0;
    }
  }

  &--title {
    display: inline-block;

    @media (min-width: 1920px) {
      max-width: 600px;
      padding-top: 50px;
    }
  }

  &--subtitle {
    color: #27433c;
    display: inline-block;
    margin: 20px 0 20px;
    max-width: 440px;

    @media (min-width: 1440px) {
      left: 27%;
      margin: 20px 0 20px 15px;
    }
  }
}

.swiper {
  overflow-y: hidden;
  height: 500px;

  @media (min-width: 1440px) {
    padding-top: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.swiper-slide {
  width: 239px;
  transition: transform 0.1s;
  padding-top: 50px;

  @media (min-width: 1280px) {
    transition: transform 0.3s;
  }

  .card {
    height: 250px;
    padding: 20px;

    @media (min-width: 1280px) {
      padding: 35px 20px;
    }

    @media (min-width: 1440px) {
      padding: 35px 20px;
    }
  }

  .card-title {
    margin-bottom: 35px;
    font-size: 1.5rem;

    @media (min-width: 1280px) {
      font-size: 1.2rem;
    }
  }

  .card-subtitle {
    @media (max-width: 1280px) {
      font-size: 1.25rem;
    }
  }

  .card-list>.text {
    @media (max-width: 1280px) {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 1440px) {
    &:hover {
      transform: scale(1.2);

      .card {
        box-shadow: 10px 10px 20px #1c443c;
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination {
  top: 81%;
  color: black;

  @media (min-width: 1440px) {
    top: 90%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  z-index: 11;

  &::after {
    font-size: 22px;
  }
}

.swiper-pagination {
  top: 80%;

  @media (min-width: 1440px) {
    top: 88%;
  }
}

.swiper-button-prev {
  left: 20%;

  @media (min-width: 1440px) {
    left: 44%;
  }
}

.swiper-button-next {
  left: 73%;

  @media (min-width: 1440px) {
    left: 55%;
  }
}

.swiper-pagination-bullet-active {
  background-color: black;
}