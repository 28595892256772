.homepage-container {
  color: white;
  background-color: #002e28;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 60px 20px;
  text-align: right;
  justify-content: flex-end;

  @media (min-width: 1280px) {
    padding: 100px;
  }
}
.homepage-title {
  font-size: 5rem;

  @media (min-width: 360px) {
    font-size: 6rem;
  }

  @media (min-width: 375px) {
    font-size: 7rem;
  }

  @media (min-width: 1280px) {
    font-size: 12rem;
    flex-grow: 1;
  }
}
.homepage-footer {
  align-self: flex-end;
  margin-bottom: 80px;
  max-width: 600px;
}
.homepage-subtitle {
  display: inline-block;
  font-size: 2.3rem;
  margin-bottom: 10px;
  width: 60%;
}