.language-switch {
  color: #27433c;
  font-size: 15px;

  @media (min-width: 1280px) {
    font-size: 25px;
  }

  button {
    border: 0;
    background: none;
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: 500;
    line-height: 40px;
    font-style: normal;
    text-align: center;
    color: #27433c;
    cursor: pointer;
    margin: 0;

    @media (min-width: 1280px) {
      font-size: 25px;
    }
  }

  span {
    margin-right: 0;
    color: white;
  }

  .active {
    font-weight: bold;
  }
}