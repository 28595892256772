.process {
  &--container {
    background-color: #002e28;
    padding: 40px 20px;

    @media (min-width: 1440px) {
      padding: 50px;
    }
  }

  &--title {
    float: right;
    margin-bottom: 20px;
    text-align: right;
    text-transform: uppercase;

    @media (min-width: 1440px) {
      width: 50%;
      margin-bottom: 40px;
    }
  }

  &--content {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    color: #fefefe;
  }

  &--item-wrap {
    box-sizing: border-box;
    padding: 40px 0;

    @media (min-width: 1440px) {
      flex-basis: 50%;
      padding: 40px 20px;
    }
  }

  &--item {
    border: 3px solid #fefefe;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 20px 20px 20px 80px;
    position: relative;

    @media (max-width: 1024px) {
      border: 1px solid #fefefe;
      padding: 40px 30px 30px;
    }

    @media (min-width: 1440px) {
      border-radius: 120px;
      padding: 20px 20px 20px 140px;
    }
  }

  &--item-title {
    font-size: 15px;
  }

  &--item-number {
    background-color: white;
    background-image: url(../../images/circle-2.png);
    background-repeat: no-repeat;
    background-size: 80px 80px;
    border-radius: 50%;
    color: #7f9693;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: 900;
    position: absolute;
    left: 5px;
    top: -50px;
    width: 85px;
    height: 85px;

    @media (min-width: 1440px) {
      width: 120px;
      height: 120px;
      background-size: 118px 118px;
    }
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;

    &>div {
      margin: 0 20px;
    }
  }

  &--spacer {
    border-top: 2px solid #fff;
    width: 100px;
  }

  &--phone {
    background: url(../../images/process-phone.png) no-repeat;
    width: 100px;
    height: 120px;
  }

  &--eye {
    background: url(../../images/process-eye.png) no-repeat;
    width: 150px;
    height: 90px;
  }

  &--todo {
    background: url(../../images/process-todo.png) no-repeat;
    width: 100px;
    height: 85px;
  }

  &--plan {
    background: url(../../images/process-plan.png) no-repeat;
    width: 90px;
    height: 120px;
  }
}

.process--title.page-title {
  @media (max-width: 1024px) {
    font-size: 3.6rem;
  }
}