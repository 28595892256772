.therapie-methoden {
  &--content {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 40px;
    width: 100%;

    @media (min-width: 1280px) {
      flex-direction: row;
      margin-top: 50px;

    }
  }

  &--left {
    box-sizing: border-box;
    position: relative;
    text-align: left;

    @media (max-width: 768px) {
      background: url(../../images/dogs2.png) no-repeat;
      background-position: right -3px bottom -3px;
      background-size: 140px auto;
      box-sizing: border-box;
      padding: 0 20px;
    }

    @media (min-width: 1280px) {
      padding-left: 0;
      padding-right: 50px;
      width: 50%;
      text-align: unset;

      .bg-circle {
        width: 800px;
        height: 800px;
        left: -500px;
        background-image: url(../../images/circle-3.png);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
      }
    }
  }

  &--right {
    @media (min-width: 1280px) {
      background: url(../../images/dogs2.png) no-repeat;
      background-position: bottom left;
      background-size: 430px auto;
      box-sizing: border-box;
      padding: 0 20px;
      width: 50%;
    }

    .link-cta {
      text-decoration: none;
    }
  }

  &--item {
    color: #27433c;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-top: 16px;

    @media (min-width: 1440px) {
      font-size: 17px;
      margin-left: 20px;
      margin-top: 40px;

      &:nth-child(7),
      &:nth-last-child(7) {
        padding-left: 320px;
      }

      &:nth-child(6),
      &:nth-last-child(6) {
        padding-left: 310px;
      }

      &:nth-child(5),
      &:nth-last-child(5) {
        padding-left: 300px;
      }

      &:nth-child(4),
      &:nth-last-child(4) {
        padding-left: 280px;
      }

      &:nth-child(3),
      &:nth-last-child(3) {
        padding-left: 230px;
      }

      &:nth-child(2),
      &:nth-last-child(2) {
        padding-left: 180px;
      }

      &:nth-child(1),
      &:nth-last-child(1) {
        padding-left: 140px;
      }

      &:first-child,
      &:last-child {
        padding-left: 100px;
      }
    }
  }

  &--subtitle {
    color: #27433c;
    display: inline-block;
    margin: 20px 0;
    max-width: 440px;
  }

  &--dogs {
    width: 50%;
    height: auto;
  }

  &--button {
    margin-top: 20px;
  }
}