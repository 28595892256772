.contact {
  &-page {
    &.bg-bot-reverse {
      padding-bottom: 20px;
      background-size: 100% 250px;

      @media (min-width: 1440px) {
        background-size: 100% 270px;
      }

      @media (min-width: 1920px) {
        background-size: 100% 300px;
      }
    }
  }

  &__content {
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: auto;

      @media (min-width: 1440px) {
        width: 45%;
      }

      .message,
      .submit-message {
        font-family: "Montserrat";
        font-weight: bold;
        line-height: 11px;
        font-style: normal;
        text-align: right;
        color: #504d4d;
        padding: 7px 0;
      }

      .message {
        font-size: 11px;
      }

      .submit-message {
        padding-top: 15px;
        font-size: 16px;
      }

      input,
      select,
      textarea {
        font-family: "Montserrat";
        font-size: 15px;
        width: 100%;
        height: 40px;
        border-radius: 157px;
        background: #e9eceb;
        border-color: #e9eceb;
        border: 0;
        margin-top: 8px;
        padding: 10px 0 10px 20px;
        box-sizing: border-box;

        @media (min-width: 1280px) {
          height: 60px;
        }

        &[type="submit"] {
          width: 100%;
          margin-left: auto;
          background: #1c443c;
          border-radius: 21px;
          font-family: "Montserrat";
          font-size: 15px;
          font-weight: 500;
          line-height: 15px;
          font-style: normal;
          color: #ffffff;
          cursor: pointer;
          padding: 0;

          @media (min-width: 1280px) {
            width: 287px;
          }
        }
      }

      textarea {
        height: auto;
        border-radius: 44px;
        padding-top: 30px;
      }

      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../icons//arrow.svg");
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 50%;
        background-size: 15px;
        cursor: pointer;

        option {
          width: 287px;
          color: white;
        }
      }

      label {
        text-transform: uppercase;
        padding: 0 0 8px 20px;
        font-family: "Montserrat";
        font-weight: bold;
        line-height: 15px;
        font-style: normal;
        text-align: right;
        color: #504d4d;
      }

      .required {
        &:after {
          content: " *";
          color: #504d4d;
        }
      }

      p {
        width: 100%;
        margin: 0;
        text-align: right;
      }
    }

    &--title {
      margin: 0;
      padding: 40px 0 20px;

      @media (max-width: 1024px) {
        font-size: 3.6rem;
      }

      @media (min-width: 1440px) {
        width: 560px;
        padding: 72px 0 0 0;
      }
    }

    &--wrapper {
      display: flex;
      position: relative;
      margin-bottom: 40px;

      @media (min-width: 1440px) {
        margin-bottom: unset;
      }

      img {
        width: 500px;
        height: 520px;
        align-self: center;
      }
    }
  }

  &__field {
    padding-top: 10px;

    &:first-child,
    &:nth-child(2) {
      width: 100%;

      @media (min-width: 1920px) {
        width: 45%;
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 100%;
    }
  }
}