.methoden {
  &--page {
    position: relative;

    .menu {
      background-color: #002e28;
      position: absolute;

      @media (max-width: 1024px) {
        padding: 20px;
      }

      @media (min-width: 1440px) {
        background-color: unset;
        padding-top: 0;
        top: 10px;
      }
    }
  }

  &--item-1,
  &--item-2 {
    padding-right: 85px;

    @media (max-width: 1024px) {
      padding-right: 0;
    }
  }

  &--item-2,
  &--item-4 {
    padding-top: 20px;

    @media (min-width: 1440px) {
      padding-top: 30px;
    }
  }

  &--item-3 {
    @media (min-width: 1440px) {
      padding-top: 650px;
    }
  }

  &--item-2,
  &--item-3 {
    @media (min-width: 1440px) {
      text-align: right;
    }
  }

  &--container {
    padding: 40px 20px 60px;

    @media (max-width: 1024px) {
      padding-top: 110px;
    }
  }

  &--image-container {
    display: flex;
    flex-direction: column;

    @media (min-width: 1440px) {
      background: url("../../images/horse.png") no-repeat center center;
      background-size: 800px 1000px;
      flex-direction: unset;
    }
  }

  &--left {
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-sizing: border-box;

    @media (min-width: 1440px) {
      width: 50%;
    }

    .methoden--item-3 {
      display: none;

      @media (min-width: 1440px) {
        display: block;
      }
    }
  }

  &--right {
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-top: 20px;

    @media (min-width: 1440px) {
      width: 50%;
      padding-top: 830px;
    }

    @media (min-width: 1600px) {
      padding-top: 800px;
    }
  }
}

.button-container {
  display: flex;
  padding-top: 20px;

  @media (min-width: 1440px) {
    justify-content: flex-end;
    padding-top: unset;
  }
}