@font-face {
  font-family: Montserrat;
  font-weight: 100;
  src: url(./fonts/Montserrat-Thin.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  src: url(./fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url(./fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url(./fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url(./fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url(./fonts/Montserrat-Bold.ttf);
}

html {
  font-size: 100%;
  overflow-x: hidden;
  font-size: 11px;

  @media (min-width: 1280px) {
    font-size: 15px;
  }

  @media (min-width: 1600px) {
    font-size: 16px;
  }
}

body {
  margin: 0;
  font-family: "Montserrat";

  button,
  a {
    font-family: "Montserrat";
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}

.bg-top {
  background: url(./images/bg-header.png) no-repeat;
  background-size: 100% 200px;
  padding-top: 150px;
}

.bg-top-reverse {
  background: url(./images/bg-header-reverse.png) no-repeat;
  background-size: 100% 200px;
  padding-top: 150px;
}

.bg-bot {
  background: url(./images/bg-footer.png) no-repeat;
  background-position: 0 100%;
  background-size: 100% 200px;
  padding-bottom: 50px;
}

.bg-bot-reverse {
  background: url(./images/bg-footer-reverse.png) no-repeat;
  background-position: 0 100%;
  background-size: 100% 200px;
  padding-bottom: 50px;
}

.bg-dog-image {
  background: url(./images/shop-image.png) no-repeat;
  height: 85vh;
  background-position: bottom;
  background-size: 100%;

  @media (min-width: 1600px) {
    background-position: right;
    background-size: 60%;
    height: 100vh;
  }
}

.page {
  min-height: 100vh;
}

.page-title {
  font-size: 4rem;
  font-weight: 600;
  line-height: 0.85;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    text-align: center !important;
  }

  @media (min-width: 1600px) {
    font-size: 6rem;
  }
}

.text {
  font-size: 1.4rem;

  @media (min-width: 1280px) {
    font-size: 0.9rem;
  }
}

.text-large {
  font-size: 1.5rem;
}

.text-xlarge {
  font-size: 2rem;
}

.text-green {
  color: #27433c;
}

.text-dark-green {
  color: #002d28;
}

.text-dark-grey {
  color: #434343;
}

.text-white {
  color: #fefefe;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.color-light {
  color: #dfe3e2;
}

.color-dark {
  color: #254b47;
}

.green-button {
  background-color: #1c443c;
  border: none;
  border-radius: 21px;
  color: #fefefe;
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.85;
  text-transform: uppercase;
  width: 100%;
  height: 41px;

  @media (min-width: 1280px) {
    display: inline-block;
    font-size: 15px;
    width: 287px;
  }
}

.page-content {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;

  @media (min-width: 1280px) {
    max-width: 1280px;
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (min-width: 1440px) {
    max-width: 1440px;
  }

  @media (min-width: 1600px) {
    max-width: 1600px;
  }

  @media (min-width: 1920px) {
    max-width: 1920px;
  }
}

button .link-cta {
  text-decoration: none;
  color: #fefefe;
}

button .mobile-link-cta {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 3;
}