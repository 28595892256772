.extended-footer {
  position: relative;
  display: flex;
  background: #1c443c;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  @media (min-width: 1440px) {
    flex-direction: column;
    color: black;
    background: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: unset;
  }

  .menu__right {
    * {
      margin-left: 2rem;
      margin-right: 0;
    }
  }

  &__designer-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    p {
      font-family: "Montserrat";
      font-size: 15px;
      font-weight: 500;
      line-height: 15px;
      font-style: normal;
      text-align: right;
      color: black;
      margin-bottom: 12px;

      @media (min-width: 1440px) {
        color: white;
      }
    }

    a {
      width: 287px;
      height: 35px;
      border-radius: 21px;
      background: #ffffff;
      font-family: "Montserrat";
      font-size: 15px;
      font-weight: 500;
      line-height: 35px;
      font-style: normal;
      text-align: center;
      color: #002e28;
      margin-top: 14px;
      text-decoration: none;
    }
  }

  &__social-menu {
    display: flex;

    @media (max-width: 1024px) {
      margin: 0 auto;
    }

    .social-media-links-container {
      margin-right: 53px;
    }
  }

  .menu {
    width: auto;
    padding-top: 0;
  }

  .social-media-link {
    width: 35px;
    height: 35px;
    margin-right: 30px;
    margin-left: 0;
  }
}