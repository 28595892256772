@keyframes side-menu-open-animation-mobile {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
@keyframes side-menu-open-animation-desktop {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 50%;
    opacity: 1;
  }
}
.popup-content {
  -webkit-animation: side-menu-open-animation-mobile 0.3s linear;
  padding: 0;
  border: 0;

  @media (min-width: 1280px) {
    -webkit-animation: side-menu-open-animation-desktop 0.3s linear;
  }
}

.side_menu_popup-overlay {
  .side_menu_popup-content {
    margin-left: auto !important;
    margin-right: 0 !important;
    height: 100%;
    width: 100%;
    @media (min-width: 1280px) {
      width: 50%;
    }
  }
}

.side-menu {
  background-color: white;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  text-transform: uppercase;

  .language-switch span {
    color: #27433c;
  }

  &--content {
    color: black;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    text-transform: uppercase;

    a:hover {
      font-weight: bold;
    }

    button,
    a {
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      font-style: normal;
      text-align: center;
      color: #27433c;
      margin-bottom: 35px;
      border: 0;
      background: none;
      text-decoration: none;

      @media (min-width: 1280px) {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 50px;
      }
    }
  }

  &--close {
    width: 100px;
    height: 30px;
    @media (min-width: 1280px) {
      width: 145px;
      height: 41px;
    }
  }

  &__button {
    width: 33.3%;
    border: 0;
    background: none;
    font-size: 15px;
    font-weight: bold;
    line-height: 25px;
    font-style: normal;
    text-decoration: none;

    @media (min-width: 1280px) {
      font-size: 25px;
      line-height: 25px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    p {
      font-size: 15px;
      font-weight: bold;
      line-height: 25px;
      font-style: normal;
      width: 33.3%;

      @media (min-width: 1280px) {
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

  .extended-footer {
    margin: auto 0 0;

    @media (min-width: 1280px) {
      margin-top: 10px;
    }

    @media (min-width: 1440px) {
      margin: 10px auto;
    }

    .social-media-links-container {
      margin-right: 0;
    }

    .social-media-link {
      margin-left: 30px;
      margin-right: 0;
    }
  }
}
