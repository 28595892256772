.core-values {
    &--container {
        background-color: #002e28;
        padding: 0 20px;

        @media (min-width: 1280px) {
            padding: 50px;
        }
    }

    &--bottom {
        height: 150px;
        background-color: #002e28;
        margin-top: -75px;

        @media (min-width: 1440px) {
            border-radius: 50%;
        }
    }

    &--title {
        padding-top: 40px;
        margin-bottom: 20px;
        line-height: 0.9;
        text-transform: uppercase;

        @media (min-width: 1024px) {
            width: 400px;
        }

        @media (min-width: 1440px) {
            padding-top: unset;
            margin-bottom: 40px;
        }
    }

    &--content {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #fefefe;
        font-size: 15px;
        padding: 0;

        @media (min-width: 1280px) {
            flex-direction: row;
            background-image: url(../../images/core-values-circle.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 400px auto;
            padding: 0 20px;
        }
    }

    &--left {
        align-items: flex-end;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (min-width: 1280px) {
            width: 50%;
            padding-right: 200px;
        }

        .core-values--item {
            background-position: right;
            padding-right: 80px;

            @media (min-width: 1280px) {
                background-position: left;
                padding-left: 100px;
            }
        }
    }

    &--right {
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        text-align: left;

        @media (min-width: 1280px) {
            width: 50%;
            padding-left: 200px;
            text-align: right;
        }

        .core-values--item {
            background-position: right;
            padding-right: 80px;

            @media (min-width: 1280px) {
                padding-left: 100px;
                padding-right: 90px;
            }
        }
    }

    &--item {
        background-repeat: no-repeat;
        background-size: 60px auto;
        margin: 20px 0;

        @media (min-width: 1280px) {
            background-size: 80px auto;
            margin: 30px 0;
        }

        &-title {
            font-weight: 500;
            padding-bottom: 5px;
        }

        &-content {
            padding: 10px 0;
            position: relative;
            font-size: 1.2rem;

            @media (min-width: 1280px) {
                border-top: 3px solid #4a6966;
                max-width: 450px;
            }

            @media (min-width: 1024px) {
                font-size: 0.9rem;
            }
        }

        .line-arrow {
            border-top: 3px solid #4a6966;
            position: absolute;
        }
    }

    &--item-1 {
        background-image: url(../../images/core-value-1.png);
        margin-left: 20px;

        .line-arrow {
            top: -1px;
            right: 2px;
            transform: rotate(225deg);
            transform-origin: top right;
            width: 160px;
        }
    }

    &--item-2 {
        background-image: url(../../images/core-value-2.png);
        margin-left: 20px;

        @media (min-width: 1440px) {
            padding-right: 50px;
            margin-left: unset;
        }

        .line-arrow {
            top: 0;
            right: 0;
            transform: rotate(180deg);
            transform-origin: top right;
            width: 100px;
        }
    }

    &--item-3 {
        background-image: url(../../images/core-value-3.png);
        margin-left: 20px;

        .line-arrow {
            top: 0;
            right: 0;
            transform: rotate(135deg);
            transform-origin: top right;
            width: 100px;
        }
    }

    &--item-4 {
        background-image: url(../../images/core-value-4.png);
        margin-right: 20px;

        .line-arrow {
            top: -1px;
            left: 2px;
            transform: rotate(135deg);
            transform-origin: top left;
            width: 165px;
        }
    }

    &--item-5 {
        background-image: url(../../images/core-value-5.png);

        @media (min-width: 1440px) {
            padding-left: 50px;
        }

        .line-arrow {
            top: 0;
            left: 0;
            transform: rotate(180deg);
            transform-origin: top left;
            width: 100px;
        }
    }

    &--item-6 {
        background-image: url(../../images/core-value-6.png);
        margin-right: 20px;

        .line-arrow {
            top: 0;
            left: 0;
            transform: rotate(225deg);
            transform-origin: top left;
            width: 85px;
        }
    }
}

.core-values--item {
    @media (max-width: 1024px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}