.impressum-section {
  margin-top: 20px;
  letter-spacing: normal;
  line-height: normal;

  &--secondary-subtitle {
    margin: 20px 0;
  }
}

.impressum-content {
  padding-top: 0;

  .menu {
    background-color: #002e28;
    padding: 20px;

    @media (min-width: 1024px) {
      background-color: unset;
      padding: 10px 10px 0;
    }
  }
}

.impressum-sections {
  padding: 40px;

  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1440px) {
    padding-top: 110px;
    padding-bottom: 30px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}