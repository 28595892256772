.menu {
  text-transform: uppercase;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px 0;

  @media (min-width: 1440px) {
    padding: 10px 10px 0;
  }

  &-icon {
    cursor: pointer;
  }

  button,
  a {
    background: none;
    border: 0;
    color: white;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }

  a.menu__return {
    width: 120px;
    opacity: 0.85;
    border-radius: 21px;
    background: #ffffff;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
    text-align: center;
    color: #002e28;
    text-decoration: none;

    @media (min-width: 1440px) {
      width: 220px;
      margin-left: 20px;
      font-size: 15px;
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 1440px) {
      * {
        margin-right: 2rem;
      }
    }
  }

  .logo {
    align-items: center;
    background: url(./../../../images/logo-circle.png) no-repeat center center;
    color: #fff;
    display: flex;
    font-size: 1.4rem;
    font-weight: bold;
    opacity: 0.8;
    text-decoration: none;
    height: 50px;

    @media (max-width: 768px) {
      background-size: 40%;
      font-size: 1rem;
    }

    @media (min-width: 1440px) {
      margin: 5px 20px;
    }
  }
}
